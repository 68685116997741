<template>
  <div>
    <header-portrate />
    <exports-print ref="exportbar" />
    
  <div>
    <bread-crumbs :items='items' />
    <tabsComp :tabs="tabs" />
      <v-row
        class="mainrow m-2"
        :style="`direction:` + lang.dir + `;margin-top:15px !important;`"
      >
        <v-row>
          <label></label>
          <v-col cols="12" md="10" sm="12" class="mt-4" >
          <form autocomplete="off">
            <v-row>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.start_date}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.end_date}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.mobile}}</label>
                <b-input-group>
                  <b-form-input class="inborder" v-model="mobile" style="background:#efefef;" />
                </b-input-group>
              </v-col>
              
              <v-col cols="12" md="2" sm="12">
                <label></label>
                <b-input-group>
                  <b-button @click="getCurrentCards()" variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;">{{lang.search}}</b-button>
                </b-input-group>
              </v-col>
            </v-row>
        </form>
        </v-col>
        <!-- <v-col cols="12" md="4" sm="12" class="text-end" v-if="!this.$store.state.licenseType.isBill"> -->
        <v-col cols="12" md="2" sm="12" class="text-end pt-7">
          <b-button
              @click="addPerMaint()"
              v-b-toggle.add_periodic_maint
              class="btn-sm btn"
              style="margin-top:7px;width:150px;background:transparent;border:none !important;margin-left:-7px !important;"
              id="multiply-button" 
            >
            <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_periodic_maint}}</div>
            
            </b-button>
            
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="12"
          style="display:none;"
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="lang.search"
                single-line
                hide-details
            ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+` ;`">
          <v-simple-table style="margin-inline-end: 20px !important;">
            <thead>
                <tr>
                    <th class="text-center backBlack" style="width:110px;border-right:1px solid #fff !important;">{{ lang.customer_type }}</th>
                    <!-- <th class="text-center" style="width:80px;">التوقيت</th> -->
                    <th class="text-center backBlack" style="">{{lang.full_name}}</th>
                    <th class="text-center backBlack" style="width:90px;">{{lang.mobile}}</th>
                    <th class="text-center backBlack" style="width:90px;">{{lang.plate_number}}</th>
                    <th class="text-center backBlack" style="width:90px;">{{lang.model}}</th>
                    <!-- <th class="text-center">الوصف</th> -->
                    <th class="text-center backBlack" style="width:110px;">{{lang.worker_name}}</th>
                    <th class="text-center backBlack" style="width:50px;">{{lang.last_update_by}}</th>
                    <th class="text-center backBlack" style="width:50px;">{{lang.last_update_date}}</th>
                    <th class="text-center backBlack" style="width:140px;">{{lang.status}}</th>
                    <th class="text-center blackBack" style="width:100px;width:50px;">الاجراء</th>
                </tr>
                <tr>
                  <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-top:1px solid #000 !important;" colspan="9">&#32;</td>
                  <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-bottom :2px solid #fff !important;border-top:1px solid #000 !important;">&#32;</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in tablerows" :key="index">
                    <td class="text-center" :style="`border:1px solid #fff !important;${item.customerTypebgColor}`">{{ item._customer_type }}</td>
                    <td class="text-center">{{ item.full_name }}</td>
                    <td class="text-center">{{ item.mobile }}</td>
                    <td class="text-center">{{ item.plate_number }}</td>
                    <td class="text-center">{{ item.car_model }}</td>
                    <td class="text-center">{{ item.username }}</td>
                    <td class="text-center">{{ item._last_update }}</td>
                    <td class="text-center">{{ item._last_update_date }}</td>
                    <td class="text-center" :style="`border:1px solid #fff !important;${item._status.bgColor}`">{{ item._status[`name_`+lang.langname] }}</td>
                    <td
                    style="
                      cursor: pointer;
                      width: 85px;
                      background: blue;
                      border: 2px solid #bbb !important;
                      border-top: none !important; 
                      text-align:center;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    class="blackBack"
                    @click="editCal(item.id)" v-b-toggle.edit_calendar 
                    >{{ lang.update }} </td>
                </tr>
            </tbody>
        </v-simple-table>
        </v-col>
        <v-col cols="12">
          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="15"
                :length="pageCount"
                @input="getNext(page)"
                color="#000"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
        <div style="padding:3px;">يرجى الانتظار ... Please Wait</div>
      </div>
    <vue-snotify></vue-snotify>
  </div>
  
  <Footer />
  <addPeriodMaint ref="addPeriodMaint" />
  <updatePeriodMaint ref="updatePeriodMaint" />
  </div>
</template>

<script>
import axios from 'axios'
// import {SnotifyPosition} from 'vue-snotify';
import addPeriodMaint from '@/components/addPeriodMaint.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'
import ExportsPrint from '@/components/exports-print.vue';
import updatePeriodMaint from '@/components/updatePeriodMaint.vue'

  export default{
    name: 'Period',
    components: {
        BreadCrumbs,
        Footer,
        HeaderPortrate,
        tabsComp,
        ExportsPrint,
        addPeriodMaint,
        updatePeriodMaint
    },
    data() {
      return {
        search: '',
        allres: 0,
        cpage: 0,
        page: 1,
        pageCount:0,
        edinvoice: 0,
        mobile: '',
        excel_fields:{},
        tablerows: [],
        sd: {
          day: (new Date()).getDate(),
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        },
        ed: {
          day: (new Date()).getDate(),
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        },
      }
    },
    computed: {
      cpages: function(){
        // alert(parseFloat(this.page) * 15);
        // alert(this.pageCount);
        if(parseFloat(this.page) * 15 >= this.allres){
          return true
        }
        return false;
      },
      lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      items:function() {
          return {
            text: this.lang.invoices,
            disabled: true,
            href: '/invoicelist/',
          }
      },
      headers: function () {
        let x =  [
            { 
              text: "#", align: 'center',
              filterable: false, value: 'date',
              sortable: false,class: 'backBlack'
            },
            { 
              text: this.lang.customer_type, align: 'center',
              filterable: false, value: 'customer_type',
              sortable: false,class: 'backBlack'
            },
            {
              text: this.lang.customer, align: 'center',
              filterable: false, value: 'full_name',
              sortable: false,class: 'backBlack'
            },
            {
              text: this.lang.mobile, align: 'center',
              filterable: true, value: 'mobile',
              sortable: false, class: 'backBlack'
            },
        ];
          x.push({
            text: this.lang.inv_date, align: 'center',
            filterable: false, value: 'last_visit',
            sortable: false,class: 'backBlack'
          })
          x.push({
              text: this.lang.visit_type, align: 'center',
              filterable: false, value: 'visit_type',
              sortable: false,class: 'backBlack'
            })
            x.push({
              text: this.lang.status, align: 'center',
              filterable: false, value: 'status',
              sortable: false,class: 'backBlack'
            })
        x.push({
            text: this.lang.action, align: 'center',
            filterable: false, value: '',
            sortable: false,class: 'backBlack'
          });
        return x;
      },
      tabs: function(){
        // console.log(this.$store.getters['state'].licenseType.activemenu);
        const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
              const cM = localStorage.getItem('currentMenu');
              // console.log("cM",cM);
              if(value.main.index == cM){
                  cMenu = value.sub;
              }else if(cM == null && value.main.index == 1){
                  cMenu = value.sub;
              }
            });
           
            let t = []
            // console.log("cMenu",cMenu);
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      }
    },
    created() {
     this.getCurrentCards()
    },
    methods: {
      async getCurrentCards(){
            this.$store.state.showLoading = true
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','getAllCalendar');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[start]',this.sdate);
            post.append('data[end]',this.edate)
            post.append('data[calendar_type]',"-1")
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            )
            // console.log(response.data)
            if(response && response.data && response.data.results){
                this.tablerows = response.data.results.data;
            }
            this.$store.state.showLoading = false;
        },
      addPerMaint(){
        this.$refs.addPeriodMaint.reset();
      },
      getStartDate(){
        let d = (new Date()).getDate();
        let m = (new Date()).getMonth() + 1;
        let y = (new Date()).getFullYear();
        if(parseInt(d) < 30){
          d = parseInt(30) - parseInt(d);
          if(parseInt(m) == 1){
            m = 12
            y = parseInt(y) - 1
          }
          else if(parseInt(m) > 1){
            m = parseInt(m) - 1;
          }
        }else if(parseInt(d) == 30){
          d = 1;
        }else{
          d = parseInt(d) - parseInt(30);
        }
        //-- start date today
        // this.sd.day = (new Date()).getDate();
        // this.sd.month = (new Date()).getMonth() + 1;
        this.sd.day = 1;
        this.sd.month = 1;
        this.sd.year = y;
      },
      preparedata(){
        let exdata = {
            list: "invoiceList",
            title: this.lang.invoices,
            data: [],
        }
        for(let i=0;i<this.tablerowsF.length;i++){
            exdata.data.push(this.tablerowsF[i]);
        }
        // // console.log(exdata);
        this.$refs.exportbar.exportdata = exdata;
        this.$refs.exportbar.sdate = this.sdate;
        this.$refs.exportbar.edate = this.edate;
        
        this.$refs.exportbar.exporttitle = "";
        this.$refs.exportbar.palte_number = '';
        this.$refs.exportbar.contact_number = '';
      },
    }
  }
</script>
<style>
.backBlue{
  background: blue !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.backGold{
  background: gold !important;
  color: #000 !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff00;
  z-index: 100000;
}
</style>
